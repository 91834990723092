import "./Maintenance.scoped.scss";
import central_rounded_logo from "../../assets/logo_round_white.png";

function Maintenance() {

    return (
		<div className="global Maintenance">
			<img id="background" src={require("../../assets/handbookbg.png")} alt="" />

			<div className="divider" />
			<div className="flex-container">
				<div className="force-column">
					<img src={central_rounded_logo} alt="" />
				</div>
				<div className="title">Maintenance</div>
				<div className="content">
                <center>De nouvelles fonctionnalités arrivent.</center>
                <center>-= Nous revenons dans la journée =-</center>

				</div>
				<div style={{ height: "300px", width: "100vw" }} />
			</div>
		</div>
	);
}

export default Maintenance;
