import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./eventCard.scss";
import { useCookies } from "react-cookie";
import axios from "axios";

export default function Card({ item }) {
	const [cookies] = useCookies(["cookieConsent"]);
	const navigate = useNavigate();
	const cardRef = useRef(null);
	const inputRef = useRef(null);
	let [locknkey] = useState();
	let [timeoutId] = useState();
	item.date = item.date.replace(/ /g, "T");

	function delayedOnChange(value) {
		// Clear any previously set timeout to avoid multiple onchange events
		clearTimeout(timeoutId);
		locknkey = value;
		// Set a new timeout to wait for 500 milliseconds before calling the actual onchange handler
		timeoutId = setTimeout(() => {
			handleCodeSubmit(value);
		}, 500);
	}

	function handleCodeSubmit(value) {
		// send code to server and wait for response
		// if response is true, then navigate to application page
		// else, display error message
		axios
			.post(process.env.REACT_APP_BACKEND_URL + "/event_lock", {
				id: item.id,
				code: value,
			})
			.then((res) => {
				if (res.status === 200) {
					buttonClicked();
				} else {
					// display error message
				}
			});
	}

	const buttonClicked = () => {
        if (item.attendee_type == "") {
		navigate(
			`/application/?id=${item.id}&locknkey=${locknkey}&date=${new Date(item.date).toLocaleDateString("fr-FR", {
				day: "numeric",
				month: "long",
				year: "numeric",
			})}&session=${item.session}`
		);
        }else{
            navigate(
                `/attendeeform/?id=${item.id}&locknkey=${locknkey}&date=${new Date(item.date).toLocaleDateString("fr-FR", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                })}&session=${item.session}`
            );
        }
	};

	function getAvailablePlaces() {
		if (item.attendees >= item.max_attendees || item.disponible.toLowerCase() != "true") {
			return "COMPLET";
		}

		let nb = item.max_attendees - item.attendees;

		return `${item.state} (${nb})`;
		return "";
	}

	function handleFocus() {
		inputRef.current.select();
	}
	return (
		<div className="eventCard">
			<div className="card" ref={cardRef}>
				<h1 className={item.disponible.toLowerCase() === "true" && item.attendees < item.max_attendees ? "state room" : "state full"}>
					{getAvailablePlaces()}
				</h1>
				<h1 className="date">{new Date(item.date).toLocaleDateString("fr-FR", { day: "numeric", month: "long", year: "numeric" })}</h1>
				<h1 className="title">{item.title}</h1>
				<h1 className="subtitle">
					{"💫"} {item.subtitle} {""}
				</h1>
				<div className="overlay">
					<p>{item.description}</p>
					<label id="session">{item.session ? item.session : "Session 4 heures - 14h00 / 18h00 50€ TTC"}</label>
					<label>{item.tarif ? `${item.tarif}€ TTC` : "50€ TTC"}</label>
				</div>

				{//cookies.cookieConsent && cookies.cookieConsent == "true" ? (
				item.disponible.toLowerCase() == "true" && item.attendees < item.max_attendees ? (
					item.locknkey == "" ? (
						<div className="button-box">
							<button className="purchase" onClick={buttonClicked}>
								S'inscrire
							</button>
						</div>
					) : (
						<div className="button-box">
							<input
								type="text"
								placeholder="code"
								ref={inputRef}
								value={locknkey}
								className="locknkey"
								maxLength="6"
								onChange={(e) => delayedOnChange(e.target.value)}
								onFocus={handleFocus}
							/>
							<span className="locktext">Necessite un code de réservation</span>
						</div>
					)
				) : (
					<div className="button-box">
						<button className="purchase-disabled">Complet</button>
					</div>
				)
				/*) 
                : 
                (
					<div className="button-box">
						<button className="purchase-disabled">Locké</button>
					</div>
				)*/
				}
			</div>
		</div>
	);
}
