import React from "react";
import "./YBSAddress.scss";
import central_rounded_logo from "../../assets/logo_round_white.png";

const YBSAddress = () => {

	return (
		<div className="ybsaddress">
			<div className="logo">
				<img src={central_rounded_logo} alt="" />
			</div>
			<div className="box">
				Yellow Blue Star
				<br />
				691 chemin de Briolet
				<br />
				Lieu-dit Briolet
				<br />
				47250 Cocumont
				<br />
			</div>
		</div>
	);
};

export default YBSAddress;
