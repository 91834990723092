import React, { useState } from "react";
import { Button } from "@mui/material";
import "./legal.scoped.scss";
import central_rounded_logo from "../../assets/logo_round_white.png";
import { useCookies } from "react-cookie";

function Legal() {
    const [cookie, setCookie, removeCookie] = useCookies();
	const [cookies] = useCookies(["cookieConsent"]);

    
    const revoqueCookies = () => {
        let isSecure = process.env.NODE_ENV == "production";
		removeCookie("cookieConsent", { path: "/", secure: isSecure, sameSite: 'none' });
	};

    return (
		<div className="global legal">
			<img id="background" src={require("../../assets/handbookbg.png")} alt="" />

			<div className="divider" />
			<div className="flex-container">
				<div className="force-column">
					<img src={central_rounded_logo} alt="" />
				</div>
				<div className="title">Mentions Légales et Politique de Confidentialité</div>
				<div className="content">
					<h2>1. Cookies</h2>
					<p>
                        Les cookies sont des fichiers textes déposés sur votre ordinateur lors de la visite d’un site. Ils ont pour but de collecter des informations relatives à votre navigation et de vous adresser des services adaptés à votre terminal (ordinateur, mobile ou tablette). <br />
                        Le site yellowbluestar.com utilise des cookies pour vous permettre de bénéficier de certaines fonctionnalités et d’améliorer votre expérience de navigation. <br />
                        Les cookies sont également utilisés pour réaliser des statistiques de visites. <br />
                        Vous pouvez à tout moment désactiver les cookies en modifiant les paramètres de votre navigateur. <br />
                        Vous pouvez aussi revoquer votre consentement à l’utilisation des cookies en cliquant sur le bouton ci-dessous. <br />
                        <Button variant="contained" onClick={revoqueCookies}>Revoquer le consentement</Button><br /><br />
                        Pour en savoir plus sur les cookies, vous pouvez consulter le site de la CNIL : <a href="https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser" target="_blank" rel="noreferrer">https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser</a>
                    </p>
					<h2>2. Présentation du site</h2>
					<p>
						En vertu de l’article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, il est précisé aux
						utilisateurs du site www.yellowbluestar.com l’identité des différents intervenants dans le cadre de sa réalisation et de son suivi :
						<br />
						Propriétaire : Axelle Baille - Yellow Blue Star – Lieu dit Briolet - 691 chemin de Briolet - 47250 Cocumont.
						<br />
						Créateur : Axelle Baille <br />
						Responsable publication : Le responsable publication est une personne physique. <br />
						Hébergeur : Planethoster 4416 Rue Louis-B.-Mayer, Laval, QC H7P 0G1, Canada
					</p>
					<h2>3. Description des services fournis</h2>
					<p>
						Le site www.yellowbluestar.com a pour objet de fournir une information concernant l’ensemble des activités de la société. <br />
						Le propriétaire du site s’efforce de fournir sur le site www.yellowbluestar.com des informations aussi précises que possible. Toutefois,
						il ne pourra être tenue responsable des omissions, des inexactitudes et des carences dans la mise à jour, qu’elles soient de son fait ou
						du fait des tiers partenaires qui lui fournissent ces informations. <br />
						Toutes les informations proposées sur le site www.yellowbluestar.com sont données à titre indicatif, sont non exhaustives, et sont
						susceptibles d’évoluer. Elles sont données sous réserve de modifications ayant été apportées depuis leur mise en ligne.
					</p>
					<h2>4. Propriété intellectuelle</h2>
					<p>
						Le propriétaire du site est propriétaire des droits de propriété intellectuelle ou détient les droits d’usage sur tous les éléments
						accessibles sur le site, notamment les textes, images, graphismes, logo, icônes, sons, logiciels.
						<br />
						Toute reproduction, représentation, modification, publication, adaptation totale ou partielle des éléments du site, quel que soit le
						moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable à l’email : contact@yellowbluestar.com . <br />
						Toute exploitation non autorisée du site ou de l’un quelconque de ces éléments qu’il contient sera considérée comme constitutive d’une
						contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.
					</p>
					<h2>5. Gestion des données personnelles</h2>
					<p>
						En France, les données personnelles sont notamment protégées par la loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004,
						l’article L. 226-13 du Code pénal et la Directive Européenne du 24 octobre 1995. Sur le site www.yellowbluestar.com, le propriétaire du
						site ne collecte des informations personnelles relatives à l’utilisateur que pour le besoin de certains services proposés par le site
						www.yellowbluestar.com. L’utilisateur fournit ces informations en toute connaissance de cause, notamment lorsqu’il procède par lui-même
						à leur saisie. Il est alors précisé à l’utilisateur du site www.yellowbluestar.com l’obligation ou non de fournir ces informations.
						<br />
						Conformément aux dispositions des articles 38 et suivants de la loi 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et
						aux libertés, tout utilisateur dispose d’un droit d’accès, de rectification, de suppression et d’opposition aux données personnelles le
						concernant. Pour l’exercer, adressez votre demande à www.yellowbluestar.com par email en effectuant sa demande écrite et signée,
						accompagnée d’une copie du titre d’identité avec signature du titulaire de la pièce, en précisant l’adresse à laquelle la réponse doit
						être envoyée.
					</p>
				</div>
				<div style={{ height: "300px", width: "100vw" }} />
			</div>
		</div>
	);
}

export default Legal;
