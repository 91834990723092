import React from "react";
import { Button } from "@mui/material";
import "./style.scoped.scss";

//import Navigation from '../../components/Navigation/Navigation';

import { useRef, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

//import BackgroundWaves from "../../components/BackgroundWaves/BackgroundWaves";
//import BackgroundVideo from "../../assets/back.mp4";
import central_rounded_logo from "../../assets/logo_round_white.png";
import YBSAddress from "../../components/YBSAddress/YBSAddress";

function Landing() {
	const team_recruitment = useRef(null);
	const skills = useRef(null);
	const tests = useRef(null);
	const divisions = useRef(null);
	const predictiveAnalytics = useRef(null);
	const top = useRef(null);
	const [searchParams] = useSearchParams();
	const ref = useRef(null);

	const handleJump = () => {
		const { top } = ref.current.getBoundingClientRect();
		const offset = window.pageYOffset + top;
		window.scrollTo({
			top: offset - 200, // subtract 100 pixels from the final scroll position for an offset
			behavior: "smooth",
		});
	};


	//<video id="bgvid" autoPlay muted loop src={BackgroundVideo} type="video/mp4" />
	return (
		<div className="global landing">
			<img id="background" src={require("../../assets/handbookbg.png")} alt="" />

			<div className="divider homepage" ref={top}>
				<div className="flex-container">
					<div className="force-column">
						<img src={central_rounded_logo} alt="" />

						<div className="infos">
							<div className="title">
								Découvrez <br /> de nouvelles
								<br />
							</div>
							<div className="title noshadow">
							<label>Sensations</label>
							</div>
							<div className="subtitle">
								Venez vivre une expérience équestre inoubliable dans une ambiance conviviale et bienveillante.
								<br />
								Peu importe votre expérience ou l'âge de votre cheval, le Sun Park vous attend.
							</div>
							<br />{" "}
							<Button
								onClick={handleJump}
								className="button"
								variant="contained"
								sx={{
									color: "#000",
									borderRadius: "20px",
									fontSize: "1.3rem",
									padding: "0.8rem 1.5rem",
									fontWeight: "normal",
									textTransform: "none",
								}}
							>
								DECOUVRIR PLUS{" "}
							</Button>
						</div>
					</div>
				</div>
				{/* <BackgroundWaves /> */}
			</div>

			<div className="content">
				<div className="white">
					<div className="contentBody">
						<div ref={ref} className="contentTitle">
							Bienvenue
						</div>
						Yellow Blue Star ... <br /> <br /> Un lieu dédié au Mountain Trail.
						<br />
						<br /> Nous accordons une grande importance à la relation entre le cavalier et son cheval. Nous croyons qu'il est primordial de prendre
						le temps de comprendre son équidé et de lui expliquer chaque étape du parcours.
						<br />
						<br />
						Nous ne cherchons jamais à mettre le cheval en difficulté, mais plutôt à le guider vers la réussite en lui permettant de comprendre
						chaque obstacle.
						<br />
						<br />
						Au Yellow Blue Star, nous valorisons les cavaliers qui prennent le temps d'accompagner leur cheval dans chaque étape du parcours, sans
						jamais chercher à le brusquer.
						<br />
						<br />
						Notre approche centrée sur le cheval vous permettra de développer une relation de confiance et de complicité avec votre animal. Vous
						pourrez ainsi profiter pleinement de l'expérience unique qu'offre le Mountain Trail, tout en vous amusant en pleine nature.
						<br />
						<br />
						Nous sommes impatients de vous accueillir et de vous aider à découvrir le Mountain Trail dans les meilleures conditions.
					</div>
                    <div style={{height: "50px"}}></div>
				</div>
				<div style={{height: "250px"}}>
						<YBSAddress />
				</div>
				{/*
				<div className="black">
					<div className="contentBody">
						<div className="contentTitle">Passionnés par les chevaux</div>
						Les problématiques abordées, sans que cette liste soit exhaustive : <br /> <br /> • Comment le cheval apprend <br /> • Comment débuter /
						améliorer une relation avec un cheval <br /> • Comment entretenir les pieds de son cheval entre deux parages (pour les pieds nus), quel
						matériel utiliser <br /> • Travailler aux longues renes <br /> • Poser un code à un cheval <br /> • Transférer un code (à pied/monté){" "}
						<br />
						• Rendre agréable au cheval quelque chose qui de prime abord ne lui est pas agréable <br /> • Comprendre la sensibilisation /
						désensibilisation <br /> • En apprendre plus sur une partie du matériel western et sur les ennasures (bosal, hackamore texan, licol
						corde) <br /> • Comment soigner les bobos de pres, les premiers gestes à faire ou à ne surtout pas faire en fonction du problème <br />{" "}
						• Comment organiser son pré pour se faciliter la vie et celle de nos chevaux <br /> • Amener son cheval à gérer son stress face à l
						inattendu ou à l'inconnu <br /> <br />
						L'objectif est de vous partager toutes mes connaissances acquises au cours de ces 12 années passées avec mes chevaux. Acquises au fil
						des difficultés rencontrées qu'il a fallu une à une surmonter. <br /> <br /> Je ne me substituerai pas à vous avec votre cheval. Le
						"leadership " ne se transmet pas. Il s'acquiert. Je ne rendrai pas votre cheval meilleur, mais je vous permettrai à vous et lui de vous
						comprendre, d'apprendre l'un de l'autre et que chacun de vous soit gagnants. L'objectif est bien de vous rendre autonome. <br /> <br />{" "}
						Si vous souhaitez de plus amples renseignements, n'hésitez pas à nous contacter. <br /> <br /> Localisation : dans le 47 (lot et
						garonne), à la limite du 33 (gironde) entre La réole, Marmande et Casteljaloux, à 10 min de l'A62. Le lieu est calme, légèrement
						valloné, entoure de pré et de forêt, sans etre isolé <br />
					</div>
				</div>
				<div className="white">
					<div className="contentBody">
						<div className="contentTitle">Les chevaux, ce n'est pas votre passion ?</div>
						Il n' est pas nécessaire d' aimer les chevaux et si vous en avez peur, nous avons Ice. <br /> Ice est une ponette blanche, aux yeux
						bleus mesurant 1,10m les sabots levés. Elle est âgée de 3 ans. Elle est gentille, câline, douce. Elle vous fera très vite oublier votre
						appréhension. <br /> Nos 6 chevaux sont d'âges, de races, de caractères différents; ils ont tous en commun leur gentillesse. Ils sont
						également libres de dire non, poliment bien sûr. Ils ne sont pas formatés à quoique ce soit. <br /> A travers différents exercices avec
						eux, vous allez prendre conscience de votre attitude, de votre gestuelle, de votre état d'esprit. Vous allez prendre conscience de votre
						capacité à faire bouger les choses (au sens propre comme au figuré). <br /> Cette expérience se fait seule ou en groupe. Notre priorité
						est que vous vous sentiez bien, non jugés, libre d'expérimenter, libre d'être vous même. Le cheval vous amènera à l'être. <br /> Tout
						ceci se passe dans un lieu ouvert mais couvert, à l'abri de la pluie et du soleil. <br /> <br />
					</div>
				</div>
                */}{" "}
			</div>
		</div>
	);
}

export default Landing;
