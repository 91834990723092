import React, { Component, useState, useEffect } from "react";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";
import "./style.scoped.scss";


import map from "../../assets/map/Maps.png";


function Inscriptions() {
	const navigate = useNavigate();
	const [modalIsOpen, setIsOpen] = useState(false);
	const [data, setData] = useState([]);

	ReactModal.defaultStyles.overlay.backgroundColor = "rgba(0, 0, 0, 0.55)";
	const customModalStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			backgroundColor: "white",
			border: "1px solid black",
			color: "black",
			width: "80%",
			height: "80%",
		},
	};
	return (
		<div className="global map">
			<div className="container">
				<div className="item-container">
					<div className="item" id="a1"></div>

				</div>
				<img src={map} alt="" />
			</div>
				
				
			
		</div>
	);
}

export default Inscriptions;
