import React from "react";
import { useCookies } from "react-cookie";
import "./CookieConsent.scss";
import CancelIcon from "@mui/icons-material/Cancel";

const CookieConsent = () => {
	const [cookies, setCookie] = useCookies(["cookieConsent"]);
	const giveCookieConsent = () => {
        let isSecure = process.env.NODE_ENV == "production";
		setCookie("cookieConsent", true, { path: "/", secure: isSecure, sameSite: 'none' });
	};
	const giveCookieDecline = () => {
        let isSecure = process.env.NODE_ENV == "production";
		setCookie("cookieConsent", false, { path: "/", secure: isSecure, sameSite: 'none' });
	};


	return (
		<div className="cookie-consent">
			<div className="cross" onClick={giveCookieDecline}>
				<CancelIcon />
			</div>
			<div className="box">
				<p>
					Nous utilisons des cookies pour nous assurer que vous obtenez la meilleure expérience sur notre site web.<br/> Ils sont aussi nécessaires pour
					vous inscrire aux événements. <a href={"/legal"}>En savoir plus.</a>
				</p>
				<button onClick={giveCookieConsent}>Accepter</button>
				<button onClick={giveCookieDecline}>Refuser</button>
			</div>
		</div>
	);
};

export default CookieConsent;
