import React, { useState } from "react";
import { Button } from "@mui/material";
import "./epanouissement.scoped.scss";
import bgwave from "../../assets/bg-wave.png";
import central_rounded_logo from "../../assets/logo_round_white.png";

function Epanouissement() {
	return (
		<div className="global reglement">
			<img id="background" src={require("../../assets/handbookbg.png")} alt="" />

			<div className="divider" />
			<div className="flex-container">
				<div className="force-column">
					<img src={central_rounded_logo} alt="" />
				</div>
				<div style={{ height: "30px" }} />
				<div className="title">Epanouissement par le cheval</div>
				<div className="content">

					<h2>Notre société nous oblige à :</h2>
					<ul>
						<li>• Compter notre temps</li>
						<li>• Nous projeter dans le futur</li>
						<li>• Donner beaucoup de valeur à l'avis d'autrui.</li>
					</ul>

					<h2>Et pourtant, le bonheur se trouve plutôt lorsque l'on :</h2>
					<ul>
						<li>• Prend son temps</li>
						<li>• Savoure le présent</li>
						<li>• Prend en considération son propre avis</li>
					</ul>

					<p>Il est important de comprendre les besoins de notre société et de les prendre en compte, tout comme il est important d'écouter ses propres besoins. Les deux ne sont pas antinomiques, bien au contraire.</p>

					<p>C'est un juste milieu qui fera de nous des personnes qui peuvent évoluer sereinement tous ensemble.</p>

					<p>L'épanouissement réside dans l'équilibre.</p>

					<p>Le cheval fonctionne à l'inverse de notre société. Il a une notion du temps bien différente, il est toujours dans le présent et personne n'émet d'avis. Seul l'action au présent compte. Il est également d'une grande subtilité et un maître en lecture du comportement inter-espèce. Il en va de sa survie.</p>

					<p>Au côté du cheval, vous apprenez à vivre l'instant présent et à être en phase avec vous-même. Le corps et l'esprit doivent aller dans la même direction.</p>
					<p>Par son absence de jugement, son attitude toujours juste, il permet à l'humain de prendre confiance en lui. Il lui permet d'oser, de se tromper, d'essayer à nouveau. Le cheval guide l'humain dans la détermination.</p>

					<h2>Vous souhaitez :</h2>
					<ul>
						<li>• Vous ressourcer</li>
						<li>• Apprendre à gérer certaines émotions (la peur, la colère, la frustration, …)</li>
						<li>• Retrouver de l'énergie</li>
						<li>• Trouver ou retrouver votre confiance en vous</li>
						<li>• Passer un moment de détente</li>
						<li>• Discuter</li>
						<li>• Vous retrouver avec vous-même</li>
						<li>• Canaliser une énergie</li>
						<li>• Améliorer votre communication avec autrui</li>
						<li>• Retrouver une communication entre vous et un proche</li>
						<li>• Apprendre à dire non</li>
						<li>• Savoir prendre votre place</li>
					</ul>

					<p>Que ce soit un ou plusieurs de ces souhaits, avec mes chevaux, nous pouvons vous apporter cela.</p>

					<p>Nul besoin de connaître les chevaux, et si le cheval est un animal qui vous effraie (et c'est bien normal), Ice pourra sûrement vous rassurer. C'est une ponette qui mesure 1.08m, de couleur crème avec des yeux bleus. Elle est d'une grande douceur et d'une grande gentillesse.</p>


					<h2>Durée de la séance :</h2>
					<p>1h30 dont 1h00 avec le cheval</p>

					<h2>Séance individuelle</h2>
					<p>Tarif :</p>
					<p>35€ TTC (TVA 20%) par personne</p>

					<h2>Séance en groupe privé (avec vos amis)</h2>
					<p>Tarif :</p>
					<p>30€ TTC (TVA 20%) par personne à partir de 2 personnes - maximum 4 personnes</p>

					<h2>Prévoir :</h2>
					<p>Des chaussures fermées et une tenue chaude en hiver.</p>

					<h2>Aucune connaissance du cheval n'est nécessaire.</h2>

					<h2>Toute la séance se passe à pied.</h2>

					<p>Non accessible aux auditeurs libres.</p>


				</div>
				<div style={{ height: "300px", width: "100vw" }} />
			</div>
		</div>
	);
}

export default Epanouissement;
