import React, { useState } from "react";
import { Button } from "@mui/material";
import "./reglement.scoped.scss";
import bgwave from "../../assets/bg-wave.png";
import central_rounded_logo from "../../assets/logo_round_white.png";

function Reglement() {
	return (
		<div className="global reglement">
			<img id="background" src={require("../../assets/handbookbg.png")} alt="" />

			<div className="divider" />
			<div className="flex-container">
				<div className="force-column">
					<img src={central_rounded_logo} alt="" />
				</div>
				<div className="title">Règlement Intérieur du Domaine Yellow Blue Star </div>
				<div className="content">


					<h2>Article 1 : Objet du règlement</h2>
					<p>Le présent règlement a pour objet de définir les conditions d'utilisation et de comportement à respecter par toute personne ayant réservé une session. Par la suite, cette personne sera définie par le terme "le client".</p>

					<h2>Article 2 : Respect du règlement</h2>
					<p>Le client s'engage à respecter ce règlement intérieur. En cas de non-respect, des sanctions pourront être appliquées, pouvant aller jusqu'à l'exclusion immédiate.</p>

					<h2>Article 3 : Interdictions</h2>
					<p>Il est formellement interdit de :</p>
					<ul>
						<li>• Fumer ou vapoter sur l'ensemble de la propriété.</li>
						<li>• Consommer de l'alcool.</li>
						<li>• Amener des chiens, même tenus en laisse ou laisser dans la voiture (aucune présence d'ombre sur le parking).</li>
					</ul>

					<h2>Article 4 : Respect des lieux</h2>
					<p>Le client s'engage à :</p>
					<ul>
						<li>• Ramasser les crottins et les déposer dans les poubelles prévues exclusivement à cet effet.</li>
						<li>• Laisser les lieux dans l'état où ils les ont trouvés, en veillant à ne pas causer de dégradation.</li>
						<li>• Ne laisser aucun déchet derrière eux.</li>
						<li>• Ne pas laisser marcher leur chevaux sur l'herbe si le terrain n'est pas sec.</li>
					</ul>

					<h2>Article 5 : Droit à l'image</h2>
					<p>Toute personne présente à une session accepte d'être photographiée ou filmée dans le cadre de la promotion du site. Si vous ne souhaitez pas que votre image soit utilisée, veuillez-vous adresser à l'organisateur.</p>

					<h2>Article 6 : Sanctions</h2>
					<p>En cas de non-respect du présent règlement, l'organisateur se réserve le droit d'appliquer des sanctions, pouvant aller jusqu'à l'exclusion immédiate du parcours et de la propriété. Le client concerné pourra également être tenu responsable des éventuels dommages causés.</p>

					<h2>Article 7 : Modifications du règlement</h2>
					<p>L'organisateur se réserve le droit de modifier le présent règlement à tout moment, sans préavis. Le client est invité à consulter régulièrement le règlement pour prendre connaissance des éventuelles modifications.</p>

					<h2>Article 8 - Acceptation du règlement</h2>
					<p>En procédant à la réservation d'une session, le client accepte de se conformer au présent règlement intérieur.</p>

					<h2>Article 9 - Accès et réservation</h2>
					<p>L'accès au lieu Yellow Blue Star est accessible uniquement aux personnes inscrites à une session.</p>

					<h2>Article 10 - Tarifs et paiement</h2>
					<p>Les tarifs sont affichés lors de votre réservation sur le site. Le paiement se fait en ligne au moment de l'inscription. Celle-ci sera validée en même temps que votre paiement.</p>

					<h2>Article 11 - Politique de remboursement</h2>
					<p>Les demandes d'annulation effectuées au moins 10 jours avant l'événement recevront un remboursement complet (moins des frais d'administration de 15,00 €). Elles devront être envoyées par email à contact@yellowbluestar.com, accompagnées d'un RIB. Vous devrez préciser dans l'email : le jour de l'événement, le nom du cheval et son numéro de transpondeur.</p>
					<p>Les demandes reçues dans les 10 jours précédant l'événement ne seront pas remboursées.</p>

					<h2>Article 12 - En cas d'intempéries</h2>
					<p>En cas d'intempéries rendant les sols insuffisamment porteurs pour utiliser le mountain trail, nous proposons deux solutions:</p>
					<ul>
						<li>• Solution 1 - un parcours de Trail sera monté en carrière ou en rond de longe. Ce dernier vous permettra d'évoluer au sec s'il devait pleuvoir durant toute la session.</li>
						<li>• Solution 2 -  deux autres dates vous seront proposées, vous choississez l'une des deux. </li>
					</ul>
					<p> Dans le cas d'une nouvelle intempérie pour la nouvelle date, ou dans le cas où l'intempérie intervient moins de 24h00 avant la date de votre venue, seule la solution 1 (parcours en carriere / rond de longe) sera proposée.</p>
					<p>Il n'y a aucun remboursement</p>

					<h2>Article 13 - Annulation de la session</h2>
					<p>En cas de forces majeures, telle qu'une météo dangereuse ou bien la propriétaire du lieu se trouvant dans l'incapacité de vous recevoir, un email pour vous prévenir du report de la session vous serait envoyé. Un choix parmi 2 dates vous sera proposé. Ce report ne donnera lieu à aucun remboursement ou modification du tarif.</p>

					<h2>Article 14 : Les différentes types de session</h2>
					<p>Session type 1 - Le Mountain Trail, le Ranch Trail et le Trail. Vous venez avec votre cheval profiter des installations.</p>
					<p>Session type 2 - L'aide aux cavaliers dans leur relation avec leur cheval et la gestion de leur émotion. Avec votre cheval, en fonction de votre besoin, plusieurs ateliers sont mis en place pour vous aider, vous et votre cheval.</p>
					<p>Session type 3 - L'épanouissement par le cheval. Vous venez sans cheval. Il n'est pas nécessaire de connaître les chevaux. À l'aide des 6 équidés du Yellow Blue Star, vous venez passer le moment que vous recherchez.</p>

					<h2>Article 15 - Équipement pour l'épanouissement par le cheval</h2>
					<p>Prévoir des chaussures appropriées et une tenue correcte. Prévoir une tenue chaude en hiver.</p>

					<h2>Article 16 - Équipement du cavalier</h2>
					<p>Le port du casque est obligatoire pour les personnes mineures, le gilet est conseillé. Le casque est fortement recommandé pour les personnes majeures. Prévoir des chaussures appropriées et une tenue correcte.</p>

					<h2>Article 17 - Équipement du cheval</h2>
					<p>Tout type d’équitation est la bienvenue dans le respect du cheval.</p>
					<p>Votre cheval doit, dans la mesure du possible, être équipé de :</p>
					<ul>
						<li>• Un licol corde</li>
						<li>• Une longe de 3.70m minimum</li>
						<li>• Un stick de type "carrot stick"</li>
					</ul>
					<p>Les protections en néoprène (type western) sont fortement recommandées.</p>
					<p>Dans le cas d'une session montée, prévoir :</p>
					<ul>
						<li>• Une selle</li>
						<li>• Un tapis</li>
						<li>• Un filet ou une ennasure</li>
					</ul>
					<p>Dans le cas d'une session aux longues rênes, prévoir des longues rênes.</p>
					<p>Il est interdit :</p>
					<ul>
						<li>• D'utiliser un enrênement, quel qu'il soit,</li>
						<li>• D'utiliser une muserolle croisée,</li>
						<li>• De monter à cru ou avec un tapis de monte à cru,</li>
						<li>• De faire usage de manière inappropriée de ses éperons et de son stick,</li>
						<li>• D'utiliser des bandes et des protections à coques.</li>
					</ul>

					<h2>Article 18 - Foin et eau</h2>
					<p>Prévoyez du foin et un seau pour l'eau. Les chevaux seront attachés au van durant leur préparation. Aucun cheval ne doit rester sans surveillance.</p>

					<h2>Article 19 - Responsabilité</h2>
					<p>Le client est responsable de sa propre sécurité et de celle de sa monture lorsqu'ils utilisent le parcours de Mountain Trail. Le client reconnaît utiliser les installations (Mountain Trail, carrière) en connaissance des lieux, des capacités de son cheval et de son niveau équestre. Il est donc seul responsable de tout accident qui surviendrait lors de leur utilisation. Toute dégradation faite par le cavalier ou son cheval sera à la charge du cavalier.</p>


					<h2>Article 20 - Comportement et respect des équidés</h2>
					<p>Tout client est tenu de faire preuve de courtoisie et de respect à l'égard du personnel de l'établissement, des autres clients et des équidés. Toute attitude violente ou inappropriée à l'égard des équidés sera sanctionnée.</p>

					<h2>Article 21 - Licence</h2>
					<p>Pour accéder aux sessions avec votre cheval, vous devrez nous présenter votre licence en cours de validité.</p>

					<h2>Article 22 - Suivi Sanitaire</h2>
					<p>En réservant, vous déclarez sur l'honneur que :</p>
					<ul>
						<li>• Le cheval sera exempt de tout symptôme de maladie depuis au moins les 15 jours précédents la date de l'événement auquel vous vous serez inscrit.</li>
						<li>• Le cheval n'aura pas côtoyé de nouveau cheval durant les 15 jours précédents l'événement ou que le cheval fréquenté sera également exempt de tout symptôme de maladie depuis au moins 15 jours quand les chevaux auront été mis en contact.</li>
						<li>• Le cheval aura été vermifugé de plus de 7 jours avant la date de l'évènement.</li>
					</ul>
					<p>Dans le cas où votre cheval présenterait des symptômes de maladie dans les 15 jours précédents l'évènement, nous vous demandons de nous prévenir dès que possible. Sur présentation d'un justificatif (ordonnance vétérinaire), nous vous proposerons une nouvelle date parmi un choix de 2 dates.</p>

					<h2>Article 23 : Accompagnants lors de l'inscription avec un cheval</h2>
					<p>Chaque personne inscrite avec son cheval est autorisée à venir avec un maximum de trois (3) accompagnants. Tout mineur inscrit avec son cheval devra être accompagné d'une personne majeure, soit inscrite en tant qu'accompagnante soit inscrite elle-même avec son cheval. Un supplément sera facturé pour chaque accompagnant.</p>
					<p>Afin d'éviter tout risque de vol ou d'accident, il n'est pas autorisé de rester sur l'aire de stationnement ou dans le véhicule. Toutes les personnes présentes sont réputées participer à l'activité. Le cheval peut être travaillé soit par son cavalier soit par son ou ses accompagnants.</p>

					<h2>Article 24 : Auditeur libre</h2>
					<p>Un auditeur libre est une personne souhaitant assister à une session de type 1, présente sur le site, mais sans son cheval.</p>
					<p>Il devra s'inscrire à la session "Auditeur libre".</p>
					<p>En attendant que cette partie soit automatisée, si vous souhaitez vous inscrire à une session en auditeur libre, envoyez un message par Messenger à Yellow Blue Star en précisant la session à laquelle vous souhaitez assister.</p>
					<p>Toute personne non inscrite se verra refuser l'accès à l'entrée.</p>


				</div>
				<div style={{ height: "300px", width: "100vw" }} />
			</div>
		</div>
	);
}

export default Reglement;
