import React from "react";
import { Link, useLocation } from "react-router-dom";

import { ReactComponent as Burger } from "../../assets/burger.svg";

import { useTransition, animated } from "react-spring";
import "./style.scoped.scss";

import { useState } from "react";
import { useEffect } from "react";

import HomeIcon from "@mui/icons-material/Home";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import InfoIcon from "@mui/icons-material/Info";
import LandscapeIcon from "@mui/icons-material/Landscape";
import FacebookIcon from "@mui/icons-material/Facebook";
import FavoriteIcon from "@mui/icons-material/Favorite";
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import { isSafari } from "react-device-detect";

import branding_logo from "../../assets/logo_column_white.png";

function Navigation() {
	const [isNavExpanded, setIsNavExpanded] = useState(false);
	const [logoSize, setLogoSize] = useState(180);
	const [isActive, setIsActive] = useState(false);
	const [scrollLimit, setScrollLimit] = useState(50);

	let navClass = isActive ? "nav-retracted" : "";

	const location = useLocation();

	function isPortrait() {
		return window.innerHeight > window.innerWidth;
	}
	function isPaysage() {
		return window.innerHeight < window.innerWidth;
	}
	function burger() {
		if (window.scrollY > scrollLimit) {
			return isNavExpanded ? "burger-container open" : "burger-container closed";
		}
		return isNavExpanded ? "burger-container open" : "burger-container closed closedRectracted";
	}

	const handleScroll = () => {
		let logoMin = 100;
		let logoMax = 180;

		if (window.innerWidth <= 700) {
			logoMax = 100;
			logoMin = 80;
		}

		setIsActive(window.scrollY > scrollLimit);
		setLogoSize(window.scrollY > scrollLimit ? logoMin : logoMax);
		const navbar = document.querySelector("nav");
		//navbar.style.backgroundColor =scrollPosition > scrollLimit ? "#FFFFFFBB" : "transparent";
	};

	useEffect(() => {
		setLogoSize(window.innerWidth > 700 ? 180 : 100);
		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	const transitions = useTransition(isNavExpanded, {
		from: {
			opacity: 0,
			width: "100%",
			height: "100%",
		},
		enter: { opacity: 1 },
		leave: { opacity: 0 },
	});

	const blurTransitions = useTransition(isNavExpanded, {
		from: {
			zIndex: 12,
			WebkitBackdropFilter: "blur(0px)",
			backdropFilter: "blur(0px)",
			opacity: 1,
			top: 0,
			position: "fixed",
			width: "fit-content",
			borderRadius: "20px",
		},
		enter: {
			WebkitBackdropFilter: "blur(40px)",
			backdropFilter: "blur(40px)",
			opacity: 1,
			top: 20,
		},
		leave: {
			WebkitBackdropFilter: "blur(0px)",
			backdropFilter: "blur(0px)",
			opacity: 1,
			top: 0,
		},
	});

	return (
		<div className="navigation">
			<header>
				<nav className={navClass}>
					<Link to="/" className="branding">
						<img src={branding_logo} style={{ height: logoSize, width: "auto", filter: window.scrollY > scrollLimit ? "invert(100%)" : "invert(0%)" }} alt="" />
					</Link>

					<ul className="navigation noWrap">
						<li>
							<Link to="/" className="link setCenter minContent">
								{" "}
								<HomeIcon /> Accueil
							</Link>
						</li>
						<li>
							<Link to="/epanouissement" className="link setCenter minContent">
								{" "}
								<SelfImprovementIcon /> Epanouissement
							</Link>
						</li>
						<li>
							<Link to="/relation" className="link setCenter minContent">
								{" "}
								<FavoriteIcon /> Relation Equine
							</Link>
						</li>
						<li>
							<Link to="/mountaintrail" className="link setCenter minContent">
								{" "}
								<LandscapeIcon /> Moutain Trail
							</Link>
						</li>
						<li>
							<Link to="/inscriptions" className="link setCenter minContent">
								{" "}
								<FormatAlignCenterIcon /> Inscriptions
							</Link>
						</li>
						<li>
							<Link to="/reglement" className="link setCenter minContent">
								{" "}
								<InfoIcon /> Règlement Intérieur
							</Link>
						</li>
						{/*
						<li>
							<Link to="/about" className="link setCenter minContent">
								{" "}
								<InfoIcon /> A propos
							</Link>
						</li>
						*/
						}{" "}
						<li>
							<a href="https://www.facebook.com/YellowBlueStars/" className="link setCenter contact-button minContent" target="blank">
								<FacebookIcon />
								Facebook
							</a>
						</li>
					</ul>

					<div className="sub-container">
						<div onClick={() => setIsNavExpanded(!isNavExpanded)} className={burger()}>
							<Burger className="burger" />
						</div>
					</div>

					{blurTransitions(
						(styles, item) =>
							item && (
								<animated.div style={styles}>
									{transitions(
										(styles, item) =>
											item && (
												<animated.div style={styles}>
													<div className={isSafari ? "dropdown-nav safari-fix" : "dropdown-nav"}>
														<div className="item">
															<Link
																to="/"
																className="link"
																onClick={() => {
																	setIsNavExpanded(!isNavExpanded);
																}}
															>
																<HomeIcon />
																Accueil
															</Link>
														</div>
														<div className="separator" />
														<div className="item">
															<Link
																to="/epanouissement"
																className="link"
																onClick={() => {
																	setIsNavExpanded(!isNavExpanded);
																}}
															>
																<SelfImprovementIcon />
																Epanouissment
															</Link>
														</div>
														<div className="separator" />
														<div className="item">
															<Link
																to="/relation"
																className="link"
																onClick={() => {
																	setIsNavExpanded(!isNavExpanded);
																}}
															>
																<FavoriteIcon />
																Relation Equine
															</Link>
														</div>
														<div className="separator" />
														<div className="item">
															<Link
																to="/mountaintrail"
																className="link"
																onClick={() => {
																	setIsNavExpanded(!isNavExpanded);
																}}
															>
																{" "}
																<LandscapeIcon />
																MountainTrail
															</Link>
														</div>
														<div className="separator" />
														<div className="item">
															<Link
																to="/inscriptions"
																className="link"
																onClick={() => {
																	setIsNavExpanded(!isNavExpanded);
																}}
															>
																{" "}
																<AnalyticsIcon /> Inscription
															</Link>
														</div>
														<div className="separator" />
														<div className="item">
															<Link
																to="/reglement"
																className="link"
																onClick={() => {
																	setIsNavExpanded(!isNavExpanded);
																}}
															>
																{" "}
																<InfoIcon />
																Reglement
															</Link>
														</div>
														<div className="separator" />
														{/*														<div className="item">
															<Link
																to="/about"
																className="link"
																onClick={() => {
																	setIsNavExpanded(!isNavExpanded);
																}}
															>
																{" "}
																<InfoIcon />A propos
															</Link>
														</div>
														<div className="separator" />
*/}{" "}
														<div>
															<a href="https://www.facebook.com/YellowBlueStars/">
																<div className="link contact-button"><FacebookIcon /> Facebook</div>
															</a>
														</div>
													</div>
												</animated.div>
											)
									)}
								</animated.div>
							)
					)}
				</nav>
			</header>
		</div>
	);
}

export default Navigation;
