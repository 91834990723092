import React from "react";
import "./style.scoped.scss";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

import { Link } from "react-router-dom";

//import { Button } from '@mui/material';

function Footer() {
	return (
		<div className="v-footer">
			<div className="inner-header flex">
				<div className="content-container">
					<div className="nav">
						<div className="column1">
							<Link to="/" className="link">
								<HomeIcon />
								Home
							</Link>
							<Link to="/about" className="link">
								<InfoIcon />
								About
							</Link>
						</div>
						<div className="column1">
							<Link to="https://www.facebook.com/YellowBlueStars/" className="link">
								<FacebookIcon />
								Facebook
							</Link>
							<Link to="https://www.instagram.com/yellowbluestar_/" className="link">
								<InstagramIcon />
								Instagram
							</Link>
						</div>
					</div>
					<div className="opt-text" />
				</div>
				<div className="inner-text" />
			</div>
			<div className="legal">
				<div>Tous les dessins, images ou photos présents sur ce site sont la propriété de Yellow Blue Star.</div> 
                <div>Toute reproduction ou utilisation sans autorisation préalable est interdite.</div>
                <div style={{fontSize: "0.7em"}}>YellowBlueStar - SIRET - 524 689 528 00031</div>
                <a href={"/legal"} style={{fontSize: "0.9em", textDecoration: "none", color: "white"}}>Mentions Légales & cookies</a>
                <div></div>
			</div>
		</div>
	);
}

export default Footer;
