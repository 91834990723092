import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import "./validation.scoped.scss";
import central_rounded_logo from "../../assets/logo_round_white.png";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";

function Validation() {
	const [event, setEvent] = useState([]);
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	useEffect(() => {
		let eid = queryParams.get("event_id");
		let aid = queryParams.get("attendee_id");
		let success = queryParams.get("success");
		axios
			.get(process.env.REACT_APP_BACKEND_URL + "/events")
			.then((response) => {
                let session_event = response.data.filter((item) => item.id == eid);
				setEvent(session_event[0]);
			})
			.catch((error) => {
				console.error(error);
			});

		if (success == "false") {
			axios.post(process.env.REACT_APP_BACKEND_URL + "/stripe-expire", {
				attendee_id: aid,
			});
		}
	}, []);

	if (queryParams.get("success") == "true") {
		return (
			<div className="global validation">
				<img id="background" src={require("../../assets/handbookbg.png")} alt="" />

				<div className="divider" />
				<div className="flex-container">
					<div className="force-column">
						<img src={central_rounded_logo} alt="" />
					</div>
					<div className="title">Félicitations !</div>

					<div className="content">
						<p>
							Nous sommes heureux de vous informer que votre inscription pour <br />
							<strong>"{event.title}"</strong>
							<br />
							a bien été enregistrée.
							<br />
							<br />
							<br />
							La session aura lieu le{" "}
							{new Date(event.date).toLocaleDateString("fr-FR", {
								day: "numeric",
								month: "long",
								year: "numeric",
							})}{" "}
							<br />
							{event.session}
							<br />
							<br />
							Vous êtes maintenant inscrit(e) sur la liste des participants.
							<br />
							<br />
							Si vous avez des questions n'hésitez pas à nous contacter. Nous serons ravis de vous aider.
							<br />
							Nous sommes impatients de vous voir !
							<br />
							<br />
							Cordialement, <br />
							L' équipe Yellow Blue Star
						</p>
					</div>
					<div style={{ height: "300px", width: "100vw" }} />
				</div>
			</div>
		);
	} else {
		return (
			<div className="global validation">
				<img id="background" src={require("../../assets/handbookbg.png")} alt="" />

				<div className="divider" />
				<div className="flex-container">
					<div className="force-column">
						<img src={central_rounded_logo} alt="" />
					</div>
					<div className="title">Paiement annulé</div>
					<div className="content">
						<h2>Annulation de votre paiement</h2>
						<p>
							Nous sommes désolés de vous informer que votre inscription à la session <br />
							<strong>"{event.title}"</strong>
							<br /> a été annulée pour des raisons indépendantes de notre volonté.
							<br />
							<br />
							Nous sommes désolés pour tout inconvénient que cela peut causer.
							<br />
							Nous vous invitons à vérifier auprès de votre banque ou de Stripe pour connaître la raison de cette annulation.
							<br />
							<br />
							Si vous le souhaitez, vous pouvez effectuer une nouvelle inscription en utilisant un autre mode de paiement. <br />
							Si vous avez des questions, n'hésitez pas à nous contacter.
							<br />
							<br />
							En espérant vous revoir bientôt.
							<br />
							<br />
							Cordialement, <br />
							L' équipe Yellow Blue Star
						</p>
					</div>
					<div style={{ height: "300px", width: "100vw" }} />
				</div>
			</div>
		);
	}
}

export default Validation;
