import React, { useState } from "react";
import { Button } from "@mui/material";
import "./style.scoped.scss";
import backgound_pic from "../../assets/wakanFull.jpg";

function About() {
	return (
		<div
			className="global background-pic about"
			style={{ backgroundImage: `url(${backgound_pic})` }}
		>
			<div className="divider" />
			<div className="flex-container">
				<div />
			</div>
		</div>
	);
}

export default About;
