import React, { useState } from "react";
import { Button } from "@mui/material";
import "./relationequine.scoped.scss";
import bgwave from "../../assets/bg-wave.png";
import central_rounded_logo from "../../assets/logo_round_white.png";

function RelationEquine() {
	return (
		<div className="global reglement">
			<img id="background" src={require("../../assets/handbookbg.png")} alt="" />

			<div className="divider" />
			<div className="flex-container">
				<div className="force-column">
					<img src={central_rounded_logo} alt="" />
				</div>
				<div style={{ height: "30px" }} />
				<div className="title">Relation Equine</div>
				<div className="content">

					<h2>Avoir un cheval, c'est...</h2>
					<ul>
						<li>• Apprendre à penser autrement.</li>
						<li>• S'affranchir de la notion du temps.</li>
						<li>• Ne rien rapporter à soi.</li>
						<li>• Vivre l'instant présent.</li>
						<li>• Avoir une réelle cohérence entre son corps et sa tête.</li>
						<li>• Savoir comprendre la subtilité.</li>
						<li>• Accepter d'être responsable du comportement de notre cheval.</li>
					</ul>

					<p>Quand on parvient à tout cela, avoir un cheval ne devient que du bonheur.</p>

					<p>Mais pour parvenir à cela, on passe par les larmes, la colère, l'incompréhension, la frustration, l'envie de tout abandonner, la peur.</p>

					<p>Il y a des hauts qui nous permettent de reprendre espoir et puis encore des bas que nous pensions ne plus revivre.</p>

					<p>A force de toute notre persévérance, de notre résignation, de nos observations, de nos essais, erreurs, on commence à trouver un chemin apaisant. On comprend que les bas ne sont rien d'autres qu'un essai et qu'il n'est juste pas bon. Rien de plus, rien de moins. Rien à prendre personnellement. Il suffit juste de trouver une nouvelle approche et d'essayer à nouveau.</p>

					<p>Le cheval vous fait comprendre ce qui est essentiel : être soi, profiter du présent, ne pas se soucier du temps, saisir l'opportunité et savoir apprécier chaque petite chose.</p>

					<h2>Vous souhaitez</h2>
					<ul>
						<li>• Améliorer votre relationnel avec votre cheval</li>
						<li>• Mieux gérer vos émotions (la frustration, la colère, …)</li>
						<li>• Ne plus faire de micro management avec votre cheval</li>
						<li>• Apprendre à gérer les pieds de votre cheval plutôt qu'à gérer sa tête</li>
						<li>• Améliorer votre communication avec lui</li>
						<li>• Savoir prendre votre place et qu'il ne soit plus "envahissant"</li>
					</ul>

					<p>Quel que soit l'un de ces besoins, nous pouvons vous aider. Nous avons la compétence, l'expérience, le savoir-faire et le savoir-être.</p>
					<p>Nous vous accueillons, vous et votre cheval, sans jugement, sans à priori, sans méthode pré-faite. Nous nous adaptons à votre couple tout au cours de la session.</p>


					<h2>Durée de la séance :</h2>
					<p>1h30</p>

					<h2>Séance individuelle</h2>
					<p>Tarif :</p>
					<p>35€ TTC (TVA 20%) par personne</p>

					<h2>Séance en groupe privé (avec vos amis)</h2>
					<p>Tarif :</p>
					<p>30€ TTC (TVA 20%) par personne à partir de 2 personnes - maximum 4 personnes</p>

					<p>Toute la séance se passe à pied.</p>

					<p>Non accessible aux auditeurs libres.</p>

				</div>
				<div style={{ height: "300px", width: "100vw" }} />
			</div>
		</div>
	);
}

export default RelationEquine;
