import React, { useState, useEffect } from "react";
import backgound_pic from "../../assets/handbookbg.png";
import "./style.scoped.scss";
import "./loader.scss";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import central_rounded_logo from "../../assets/logo_round_white.png";
import axios from "axios";

function AttendeeForm() {
	const [loading, setLoading] = useState(false);

	const [data, setData] = useState([]);

	const [lastname, setNom] = useState("");
	const [firstname, setPrenom] = useState("");
	const [address, setAdresse] = useState("");
	const [cp, setCp] = useState("");
	const [city, setCommune] = useState("");
	const [email, setEmail] = useState("");
	const [formErrors, setFormErrors] = useState({});
	const [reglement, setReglement] = useState("");
	const [attendants, setAttendants] = useState("0");
	const navigate = useNavigate();
	const location = useLocation();

	const queryParams = new URLSearchParams(location.search);

	const textError = "obligatoire";

	const handleSubmit = (event) => {
		setLoading(true);

		// submit the form data to the server
		const formData = {
			lastname,
			firstname,
			address,
			cp,
			city,
			email,
			reglement,
			//session,
			event_id: queryParams.get("id"),
			event_date: queryParams.get("date"),
			event_session: queryParams.get("session"),
		};

		event.preventDefault();
		// perform form validation
		let errors = {};

		if (!firstname) {
			errors.nom = `Nom ${textError}`;
		}

		if (!lastname) {
			errors.prenom = `Prenom ${textError}`;
		}

		if (!address) {
			errors.adresse = `Adresse ${textError}`;
		}

		if (!email) {
			errors.email = `Email ${textError}`;
		} else if (!/\S+@\S+\.\S+/.test(email)) {
			errors.email = "Invalid email format";
		}

		if (!reglement) {
			errors.reglement = `Le réglement intérieur doit être accepté`;
		}

	
		if (Object.keys(errors).length !== 0) {
			setFormErrors(errors);
			setLoading(false);
			return;
		}

		fetch(process.env.REACT_APP_BACKEND_URL + "/submit-form-data", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(formData),
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error("Network response was not ok");
				}
				return response.json();
			})
			.then((data) => {
				handleStripe({ ...formData, id: data.attendee_id });
			})
			.catch((error) => {
				console.error("There was a problem submitting the form data:", error);
				setLoading(false);
			});
	};

	// async function to send data to stripe
	async function handleStripe(formData) {
		fetch(process.env.REACT_APP_BACKEND_URL + "/checkout-service", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(formData),
		})
			.then((response) => {
				if (!response.ok) {
					setLoading(false);
					throw new Error("Network response was not ok");
				}
				return response.json();
			})
			.then((data) => {
				//console.log(data);
				try {
					let cancelUrl = `${process.env.REACT_APP_DOMAIN}/validation?success=false&event_id=${data.event_id}&attendee_id=${data.attendee_id}`;
					//console.log(cancelUrl);
					window.history.pushState(null, null, cancelUrl);
				} catch (e) {
					console.log(
						"Browser prevents window.history.pushState(). In a result, browser back button won't cancel the registration and expiration will have to occur automatically"
					);
				}
				window.location.href = data.url;
				//window.location.replace(data.url);
			})
			.catch((error) => {
				console.error("There was a problem submitting the form data:", error);
				setLoading(false);
			});
	}

	function handleCodeSubmit() {
		// send code to server and wait for response
		// if response is true, then navigate to application page
		// else, display error message
		axios
			.post(process.env.REACT_APP_BACKEND_URL + "/event_lock", {
				id: queryParams.get("id"),
				code: queryParams.get("locknkey"),
			})
			.then((res) => {
				if (res.status === 200) {
				} else {
					navigate("/inscriptions");
				}
			});
	}

	useEffect(() => {
		handleCodeSubmit();

		axios
			.get(process.env.REACT_APP_BACKEND_URL + "/events")
			.then((response) => {
				let session = response.data.filter((item) => item.id == queryParams.get("id"));
				session[0].date = session[0].date.replace(/ /g, "T");
				setData(session[0]);
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

	function clampAttendants(value) {
		let count = parseInt(value);
		count = isNaN(count) ? 0 : count;
		count = clamp(count, 0, 3);

		setAttendants(count);
	}

	function getTotal() {
		return data.tarif + attendants * data.tarif_accomp;
	}

	return (
		<div className="global background-pic cavalierform">
			<img id="background" src={backgound_pic} alt="" />
			<div className="divider" />

			<div className="flex-container">
				<div className="force-column">
					<img src={central_rounded_logo} alt="" />
				</div>

				<div className="recap">
					<div>{new Date(data.date).toLocaleDateString("fr-FR", { day: "numeric", month: "long", year: "numeric" })}</div>
                    <div>Auditeur Libre</div>
					<div>{data.session}</div>
					<div style={{ fontSize: "0.7em" }}>{getTotal() + "€ TTC"}</div>
                    <div style={{ fontSize: "0.6em" }}>(Ouverture du parking 15mn avant)</div>
                    <div style={{ fontSize: "0.5em", marginTop: "10px" }}>
                    <Link to="https://yellowbluestar.com/reglement" target="_blank" className="link" rel="noreferrer">
											Réglement Intérieur
										</Link>
                    </div>
				</div>
				<form className={isMobile ? "form-container mark" : "form-container mark blur"} onSubmit={handleSubmit}>
					<div className="sub-container">
						<div >
							<label htmlFor="" className="title">
								Informations
							</label>
							<div className="form-field">
								<label htmlFor="nom">Nom :</label>
								<input type="text" id="nom" value={lastname} onChange={(e) => setNom(e.target.value)} />
								{formErrors.nom && <span className="error-message">{formErrors.nom}</span>}
							</div>
							<div className="form-field">
								<label htmlFor="prenom">Prenom :</label>
								<input type="text" id="prenom" value={firstname} onChange={(e) => setPrenom(e.target.value)} />
								{formErrors.prenom && <span className="error-message">{formErrors.prenom}</span>}
							</div>
							<div className="form-field">
								<label htmlFor="adresse">Adresse :</label>
								<div className="input-list">
									<input type="text" id="adresse" value={address} onChange={(e) => setAdresse(e.target.value)} />
									<div className="bot">
										<input type="text" id="CP" value={cp} onChange={(e) => setCp(e.target.value)} />
										<input type="text" id="Commune" value={city} onChange={(e) => setCommune(e.target.value)} />
									</div>
								</div>
								{formErrors.adresse && <span className="error-message">{formErrors.adresse}</span>}
							</div>
							<div className="form-field">
								<label htmlFor="email">Email :</label>
								<input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
								{formErrors.email && <span className="error-message">{formErrors.email}</span>}
							</div>
							<div className="form-field">
								<div className="ffhorizontal">
									<label htmlFor="reglement">
										J'ai lu et j'accepte les{" "}
										<Link to="https://yellowbluestar.com/reglement" target="_blank" className="link" rel="noreferrer">
											termes
										</Link>{" "}
										du règlement intérieur :
									</label>
									<input type="checkbox" id="reglement" value={reglement} onChange={(e) => setReglement(e.target.checked)} />
								</div>
								{formErrors.reglement && <span className="error-message">{formErrors.reglement}</span>}
							</div>
						</div>
					</div>
					<div className="submit-container">
						<button type="submit">Paiement ({getTotal()}€)</button>
						<Link to="/inscriptions" className="back-home" style={{ textDecoration: "none" }}>
							Retour
						</Link>
					</div>
					{loading ? (
						<div className="loader-container">
							<div className="spinner" />
						</div>
					) : (
						<div />
					)}
				</form>
			</div>
			<div style={{ height: "5em" }} />
		</div>
	);
}

export default AttendeeForm;
