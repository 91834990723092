import "./App.scss";

import React, { useEffect, useLayoutEffect  } from "react";
import { Routes, Route, Outlet, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { useCookies } from "react-cookie";

import Navigation from "./components/Navigation/Navigation";
import CookieConsent from "./components/CookieConsent/CookieConsent"; //
import Footer from "./components/Footer/Footer";

import Landing from "./pages/Landing/Landing";
import Inscriptions from "./pages/Inscriptions/inscriptions";
import CavalierForm from "./pages/Forms/CavalierForm";
import AttendeeForm from "./pages/Forms/AttendeeForm";
import Reglement from "./pages/Reglement/Reglement";
import Epanouissement from "./pages/Epanouissement/Epanouissement";
import RelationEquine from "./pages/RelationEquine/RelationEquine";
import MountainTrail from "./pages/MountainTrail/MountainTrail";
import About from "./pages/About/About";
import Legal from "./pages/Legal/Legal";
import Validation from "./pages/Validation/Validation";
import Map from "./pages/Map/map";
import Maintenance from "./pages/Maintenance/Maintenance";
import { useNavigate } from 'react-router-dom';

function ScrollToTop() {
    const { pathname } = useLocation();
  
    useLayoutEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
    }, [pathname]);
  
    return null;
  }

function App() {
	const [cookie, setCookie, removeCookie] = useCookies();
	const [cookies] = useCookies(["cookieConsent"]);
	const PageLayout = ({ children }) => children;
    const navigation = useNavigate();

	const pageVariants = {
		initial: {
			opacity: 0,
			filter: "blur(10px)",
			top: -500,
		},
		in: {
			opacity: 1,
			filter: "blur(0px)",
			top: 0,
		},
		out: {
			opacity: 0,
			filter: "blur(10px)",
			top: -500,
		},
	};

	const pageTransition = {
		type: "tween",
		ease: "linear",
		duration: 0.3,
	};

	const AnimationLayout = () => {
		const { pathname } = useLocation();
		return (
			<PageLayout>
				<motion.div key={pathname} initial="initial" animate="in" variants={pageVariants} transition={pageTransition}>
					<Outlet />
				</motion.div>
			</PageLayout>
		);
	};

	/*
	useEffect(() => {
		removeCookie("cookieConsent", { path: "/" });
	}, [cookies]);
    */
	return (
		<div className="App">
			<Navigation />
            <ScrollToTop />

			<Routes>
				<Route element={<AnimationLayout />}>
					<Route path="/" element={<Landing />} />
					<Route path="/map" element={<Map />} />
					<Route path="/application" element={<CavalierForm />} />
					<Route path="/reglement" element={<Reglement />} />
					<Route path="/relation" element={<RelationEquine />} />
					<Route path="/epanouissement" element={<Epanouissement />} />
					<Route path="/mountaintrail" element={<MountainTrail />} />
					<Route path="/about" element={<About />} />
					<Route path="/inscriptionsOff" element={<Inscriptions />} />
					<Route path="/inscriptions" element={<Inscriptions />} />
					<Route path="/legal" element={<Legal />} />
					<Route path="/validation" element={<Validation />} />
					<Route path="/maintenance" element={<Maintenance />} />
                    <Route path="/attendeeform" element={<AttendeeForm />} />
				</Route>
			</Routes>

			<Footer />
			{!cookies.cookieConsent && <CookieConsent />}
		</div>
	);
}

export default App;
